import "./Header.css";
import React, { useState } from "react";
import Logo from "../assets/logo.png";
import Bars from "../assets/bars.png";
import { Link } from "react-scroll";
export default function Header() {
  const mobile = window.innerWidth <= 765 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
  const setNewState = () => {
    setMenuOpened(true);
  };
  return (
    <>
      <div className="header">
        <img className="logo" src={Logo} alt="Logo" />
        {menuOpened === false && mobile === true ? (
          <div className="bars-style" onClick={setNewState}>
            <img className="hamburger-img" src={Bars} alt="hamburger" />
          </div>
        ) : (
          <ul className="header-menu">
            <li>
              <Link
                onClick={() => setMenuOpened(false)}
                to="home"
                span={true}
                smooth={true}
                activeClass="active"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuOpened(false)}
                to="programs"
                span={true}
                smooth={true}
              >
                Programs
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuOpened(false)}
                to="reasons"
                span={true}
                smooth={true}
              >
                Why Us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuOpened(false)}
                to="plans"
                span={true}
                smooth={true}
              >
                Plans
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMenuOpened(false)}
                to="testimonials"
                span={true}
                smooth={true}
              >
                Testimonials
              </Link>
            </li>
          </ul>
        )}
      </div>
    </>
  );
}
