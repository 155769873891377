import React from "react";
import "./Plans.css";
import { plansData } from "../data/plansData";
import whiteTick from "../assets/whiteTick.png";

export default function Plans() {
  return (
    <>
      <div className="plans-container">
        <div className="blur blur-p1"></div>
        <div className="blur blur-p2"></div>
        <div className="programs-header">
          <span className="stroke-text">Ready to start</span>
          <span>Your journey</span>
          <span className="stroke-text">Now with us</span>
        </div>
        <div className="plans-card">
          {plansData.map((plan, i) => (
            <div className="plan" key={i}>
              {plan.icon}
              <span>{plan.name}</span>
              <span>$ {plan.price}</span>
              <div className="features">
                {plan.features.map((feature, i) => (
                  <div className="feature">
                    <img src={whiteTick} alt="tick" />
                    <span key={i}>{feature}</span>
                  </div>
                ))}
              </div>
              <div>
                <span>See more benifits ➡</span>
              </div>
              <button className="btn btn-join">Join Now</button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
